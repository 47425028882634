@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid lightgray;
  background: white;
}
/* GRID */
.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.row-middle {
  align-items: center;
}
.column {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
/* Calendar */
.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: white;
  border: 1px solid lightgray;
  /* height: auto; */
  margin: 0 auto;
}
.calendar .header {
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid lightgray;
}
.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}
.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: dodgerblue;
}
.calendar .header .icon:first-of-type {
  margin-left: 1em;
}
.calendar .header .icon:last-of-type {
  margin-right: 1em;
}
.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: gray;
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid #ddd;
}
.calendar .body .cell {
  position: relative;
  height: 8em;
  border-right: 1px solid lightgray;
  overflow: hidden;
  cursor: pointer;
  background: white;
  transition: 0.25s ease-out;
}
.calendar .body .cell:hover {
  background: whitesmoke;
  transition: 0.5s ease-out;
}
.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image-slice: 1;
}
.calendar .body .row {
  border-bottom: 1px solid lightgray;
}
.calendar .body .row:last-child {
  border-bottom: none;
}
.calendar .body .cell:last-child {
  border-right: none;
}
.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}
.calendar .body .cell:hover .number,
.calendar .body .selected .number {
  visibility: hidden;
}
.calendar .body .disabled {
  color: lightgray;
  pointer-events: none;
}
.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: blue;
  opacity: 0;
  font-size: 5em;
  position: absolute;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}
.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.2;
  transition: 0.5s ease-in;
}
.calendar .body .column {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.modalWrapper {
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modalContent {
  float: right;
  width: 38%;
  height: 100vh;
  background-color: #fff;
  border-left: 1px solid #ddd;

  padding: 15px 20px;
}

.modalContent h3 {
  margin: 20px 0;
}

.photoContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 170px;
  border: 1px dashed #ddd;
}

.modalContent .forms {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 20px 0;
}

.forms .left {
  width: 45%;
}

.forms .right {
  width: 45%;
}

.forms .left label {
  display: block;
  margin: 25px 0;
}

.forms .right label {
  display: block;
  margin: 25px 0;
}

.button_Horario {
  width: 100%;
  margin: 20px 0;
}

.button_Horario button {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border: 1px solid blue;
  border-radius: 4px;
  color: blue;
  font-size: 1.2rem;
}

.button_Horario button:hover {
  cursor: pointer;
  opacity: 0.5;
}
